// Zircon
//
// Comment Header before deploying to Cloudflare, for the time being...
// Also, change the API url to altair in Lookup component

import React, { useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import Home from "./components/Home"
import Header from "./components/Header"
import preCheck from "./components/preCheck"

// import Payment from "./components/Payment"
import RegNumber from "./components/RegNumber"
import Lookup from "./components/Lookup"
// import Fuckup from "./components/Lookup"
import Terms from "./components/Terms"
import { cleanseRegNumber } from "./components/util"
import { msg } from './components/message';
import initPaymentProcess from './components/paymentProcess';
// import VerifyPayment from './components/VerifyPayment';
import VerifyPromoToken from './components/VerifyPromoToken';
import VerifyPayment from './components/VerifyPayment';
import Show from './components/Show';
import { getTimeStamp } from './components/util';

// import { set } from 'lodash';


// const zirconVersion = '0.1.19'; // 2024-09-03 - deploy 2024-10-09
const zirconVersion = '0.1.20'; // 2024-10-10


// A p p  component
//
function App() {

  const navigate = useNavigate();
  const [appStatus, setAppStatus] = useState('initial');
  const [language, setLanguage] = useState('fi');
  const [regNumber, setRegNumber] = useState('CIH-493');
  const [regType, setRegType] = useState('1');
  const [vin, setVin] = useState('');
  const [agree, setAgree] = useState(true);
  const [promoToken, setPromoToken] = useState('');
  // const [payToken, setPayToken] = useState('');
  const [auto, setAuto] = useState({});
  const [moreOptions, setMoreOptions] = useState(false);
  const [prevRegNumber, setPrevRegNumber] = useState('');

  document.title = 'Autokanta';

  console.log(getTimeStamp(), 'App');

  const handleLanguageChange = (value) => {
    // console.log(getTimeStamp(), 'App, handleLanguageChange (1), language', language)
    setLanguage(value);
    // console.log(getTimeStamp(), 'App, handleLanguageChange (2), language', language)
  };

  const handleRegNumberChange = (event) => {
    // console.log(getTimeStamp(), "App, handleRegNumberChange ", event.target.value)
    setRegNumber(cleanseRegNumber(event.target.value));
    setVin('');
  }

  const handleRegTypeChange = (event) => {
    // console.log(getTimeStamp(), "App, handleRegTypeChange ", event.target.value)
    setRegType(event.target.value);
  }

  const handleAgreeChange = (event) => {
    // console.log("handleAgreeChange, event", event.target.checked);
    setAgree(event.target.checked);
    // console.log("handleAgreeChange, agree", agree)
  }

  const handleVinChange = (event) => {
    // console.log(getTimeStamp(), "App, handleVinChange ", event.target.value)
    setVin(event.target.value);
    setRegNumber('');
  }

  const handlePromoTokenChange = (event) => {
    const value = event.target.value;
    setPromoToken(value.toString());
  };

  // If the preCheck is successful, initiate the payment process, 
  // and if the payment process fails, display an alert message and 
  // navigate back to the registration number input page. 
  // If any errors occur during the process, catch them and display 
  // a generic error message to the user
  // Note that if initPaymentProcess is successful, we never come back here.
  // Success is therefore not tested
  const validateRegNumber = async (event) => {

    console.log('validateRegNumber');
    event.preventDefault();

    // bypass preCheck and payment
    if (promoToken.length > 0) {
      navigate('/verifypromotoken');
      return;
    }

    try {
      const preCheckResult = await preCheck(regNumber, vin, regType);
      if (preCheckResult.success) {
        const initPaymentResult = await initPaymentProcess(regNumber, vin, regType);
        if (!initPaymentResult.success) { // only testing failure
          alert(msg(initPaymentResult.message, language));
          navigate('/regnumber');
        }
      } else {
        alert(msg(preCheck.message, language));
        navigate('/regnumber');
      }
    } catch (error) {
      alert(msg('Muu virhe kyselyssä', language));
      navigate('/regnumber');
    }
  }

  // const handleGoBack = () => {
  //   // this function is called when the handleGoBack button is clicked in Lookup
  //   // console.log(getTimeStamp(), "App, handleGoBack");
  //   navigate('/regnumber');
  // };

  return (
    <>
      <Header zirconVersion={zirconVersion} onChange={handleLanguageChange} lng={language} />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/regnumber" element={<RegNumber setAppStatus={setAppStatus} regNumber={regNumber} agree={agree} handleAgreeChange={handleAgreeChange}
          handleRegNumberChange={handleRegNumberChange} validateRegNumber={validateRegNumber} vin={vin} handleVinChange={handleVinChange} regType={regType}
          handleRegTypeChange={handleRegTypeChange} promoToken={promoToken} handlePromoTokenChange={handlePromoTokenChange} moreOptions={moreOptions} setMoreOptions={setMoreOptions} lng={language} />} />
        <Route path="/verifypromotoken" element={<VerifyPromoToken promoToken={promoToken} regNumber={regNumber} prevRegNumber={prevRegNumber} setPrevRegNumber={setPrevRegNumber} vin={vin} regType={regType} setAuto={setAuto} lng={language} />} />
        <Route path="/verifypayment" element={<VerifyPayment setAuto={setAuto} lng={language} />} />
        <Route path="show" element={<Show auto={auto} lng={language} />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
      <Container>
        <Row>
          <Col>
            <p className="text-center mt-5">Autokanta.fi by zydeemi 2024.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-center">({zirconVersion})</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App
