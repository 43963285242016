// Zircon - Message utility component.js

import _ from 'lodash';

// m s g
//
export function msg(code, lng) {
  let result = '';

  if (undefined === code) {
    result = 'Undefined msg code';
  }
  if (code.length > 0) {
    const matchedMessage = MESSAGES.find(msg => msg.code === code && msg.lng === lng) ?? null;

    if (matchedMessage) {
      result = matchedMessage.text;
    } else {
      result = 'Msg not found ' + code;
    }
  }
  return result;
}


// a l e r t M s g
//
export function alertMsg(alerts, code, lng) {

  if (undefined === code) {
    return 'Undefined msg code';
  }
  if (code.length < 1) {
    return 'Invalid msg code ' + code;
  }

  const matchedMessage = MESSAGES.find(msg => msg.code === code && msg.lng === lng) ?? null;
  if (!matchedMessage) {
    return 'Not found msg code ' + code;
  }

  // msg found, check if there are linked alerts
  const highlightedItems = _.filter(alerts, item => _.has(item, 'highLight'));
  if (highlightedItems.length < 1) {
    // console.log('message, alertMsg 1, empty highlights array', code);
    return matchedMessage.text;
  }

  // is code found in the alerts array that have a 'highLight' property?
  const hasDesiredValue = _.some(highlightedItems, item => _.includes(item.highLight, code));

  if (hasDesiredValue) {
    // at least one item in highlightedItems has the desired value in its 'link' array
    // console.log('message, alertMsg 2, found code', code);
    return matchedMessage.text + '!';
  } else {
    // none of the items in highlightedItems have the desired value in their 'link' array
    // console.log('message, alertMsg 3, not found code', code);
    return matchedMessage.text;
  }
}


const MESSAGES =
  [
    // Header
    { 'code': 'Aloitus', 'text': 'Aloitus', 'lng': 'fi' },
    { 'code': 'Aloitus', 'text': 'Startsidan', 'lng': 'sv' },
    { 'code': 'Käyttöehdot', 'text': 'Käyttöehdot', 'lng': 'fi' },
    { 'code': 'Käyttöehdot', 'text': 'Användarvillkor', 'lng': 'sv' },
    { 'code': 'Kysymykset', 'text': 'Kysy', 'lng': 'fi' },
    { 'code': 'Kysymykset', 'text': 'Fråga', 'lng': 'sv' },
    { 'code': 'Yhteystiedot', 'text': 'Yhteystiedot', 'lng': 'fi' },
    { 'code': 'Yhteystiedot', 'text': 'Kontakta oss', 'lng': 'sv' },

    // RegNumber
    { 'code': 'Kirjoita rekisterinumero', 'text': 'Kirjoita rekisterinumero', 'lng': 'fi' },
    { 'code': 'Kirjoita rekisterinumero', 'text': 'Ange registernummer', 'lng': 'sv' },
    { 'code': 'ajoneuvolaji', 'text': 'Ajoneuvolaji', 'lng': 'fi' },
    { 'code': 'ajoneuvolaji', 'text': 'Fordonsslag', 'lng': 'sv' },
    { 'code': 'Hae auton tiedot', 'text': 'Hae ajoneuvon tiedot', 'lng': 'fi' },
    { 'code': 'Hae auton tiedot', 'text': 'Sök fordonsuppgifter', 'lng': 'sv' },
    { 'code': 'Hyväksyn käyttöehdot', 'text': 'Hyväksyn', 'lng': 'fi' },
    { 'code': 'Hyväksyn käyttöehdot', 'text': 'Jag godkänner', 'lng': 'sv' },
    { 'code': 'Lisää hakuehtoja', 'text': 'Lisää hakuehtoja', 'lng': 'fi' },
    { 'code': 'Lisää hakuehtoja', 'text': 'Flera sökalternativ', 'lng': 'sv' },
    { 'code': 'Näytä käyttöehdot', 'text': 'käyttöehdot', 'lng': 'fi' },
    { 'code': 'Näytä käyttöehdot', 'text': 'användarvillkoren', 'lng': 'sv' },
    { 'code': 'Käyttöehdot-teksti', 'text': 'Huomioithan ennen palvelun käyttöä, että palvelussa palautuvia tietoja ei saa käyttää millään tavalla lainvastaisesti tai sellaiseen käyttötarkoitukseen, jolla vaarannettaisiin henkilöiden yksityisyydensuojaa. Palvelun palauttamia tietoja on tarkoitus hyödyntää lähtökohtaisesti kuluttajien ajoneuvokaupan yhteydessä varmistamaan kaupan luotettavuutta ajoneuvotietojen tai omistajan osalta esimerkiksi selvittämällä, että myyjä omistaa ajoneuvon. Muiden hyväksyttyjen käyttötarkoitusten on liityttävä liikenneturvallisuuteen, ajoneuvoihin, ajoneuvoliikenteeseen tai sen aiheuttamiin ympäristöhaittoihin taikka ajoneuvon kuljettamiseen. Palvelua käyttäessäsi sitoudut noudattamaan edellä mainittuja ehtoja. ', 'lng': 'fi' },
    { 'code': 'Käyttöehdot-teksti', 'text': 'Huomioithan ennen palvelun käyttöä, että palvelussa palautuvia tietoja ei saa käyttää millään tavalla lainvastaisesti tai sellaiseen käyttötarkoitukseen, jolla vaarannettaisiin henkilöiden yksityisyydensuojaa. Palvelun palauttamia tietoja on tarkoitus hyödyntää lähtökohtaisesti kuluttajien ajoneuvokaupan yhteydessä varmistamaan kaupan luotettavuutta ajoneuvotietojen tai omistajan osalta esimerkiksi selvittämällä, että myyjä omistaa ajoneuvon. Muiden hyväksyttyjen käyttötarkoitusten on liityttävä liikenneturvallisuuteen, ajoneuvoihin, ajoneuvoliikenteeseen tai sen aiheuttamiin ympäristöhaittoihin taikka ajoneuvon kuljettamiseen. Palvelua käyttäessäsi sitoudut noudattamaan edellä mainittuja ehtoja. ', 'lng': 'sv' },
    { 'code': 'auto', 'text': 'auto', 'lng': 'fi' },
    { 'code': 'auto', 'text': 'bil', 'lng': 'sv' },
    { 'code': 'mp', 'text': 'moottoripyörä', 'lng': 'fi' },
    { 'code': 'mp', 'text': 'motorcykel', 'lng': 'sv' },
    { 'code': 'traktori', 'text': 'traktori', 'lng': 'fi' },
    { 'code': 'traktori', 'text': 'traktor', 'lng': 'sv' },
    { 'code': 'maastoajoneuvo', 'text': 'maastoajoneuvo', 'lng': 'fi' },
    { 'code': 'maastoajoneuvo', 'text': 'terrängfordon', 'lng': 'sv' },
    { 'code': 'perävaunu', 'text': 'perävaunu', 'lng': 'fi' },
    { 'code': 'perävaunu', 'text': 'släpvagn', 'lng': 'sv' },
    { 'code': 'siirtolupa', 'text': 'siirtolupa', 'lng': 'fi' },
    { 'code': 'siirtolupa', 'text': 'flyttningstillstånd', 'lng': 'sv' },
    { 'code': 'vientirekisteröinti', 'text': 'vientirekisteröinti', 'lng': 'fi' },
    { 'code': 'vientirekisteröinti', 'text': 'exportregistrering', 'lng': 'sv' },
    { 'code': 'mopo', 'text': 'mopo', 'lng': 'fi' },
    { 'code': 'mopo', 'text': 'moped', 'lng': 'sv' },
    { 'code': 'Ei käytössä', 'text': 'Toiminto ei ole toistaiseksi käytössä', 'lng': 'fi' },
    { 'code': 'Ei käytössä', 'text': 'Denna funktion är tillfälligt ur bruk', 'lng': 'sv' },
    { 'code': 'Sulje ikkuna', 'text': 'Paluu', 'lng': 'fi' },
    { 'code': 'Sulje ikkuna', 'text': 'Tillbaka', 'lng': 'sv' },
    { 'code': 'Syötä promokoodi', 'text': 'Syötä alennuskoodi', 'lng': 'fi' },
    { 'code': 'Syötä promokoodi', 'text': 'Skriv in din rabattkod', 'lng': 'sv' },
    { 'code': 'Rekisterinumeroa ei löydy', 'text': 'Ajoneuvoa ei löydy', 'lng': 'fi' },
    { 'code': 'Rekisterinumeroa ei löydy', 'text': 'Fordon hittas inte', 'lng': 'sv' },
    { 'code': 'Rekisterinumero virheellinen', 'text': 'Rekisterinumero virheellinen', 'lng': 'fi' },
    { 'code': 'Rekisterinumero virheellinen', 'text': 'Felaktigt reg.nummer', 'lng': 'sv' },
    { 'code': 'Muu virhe kyselyssä', 'text': 'Virhe kyselyssä', 'lng': 'fi' },
    { 'code': 'Muu virhe kyselyssä', 'text': 'Fel vid sökning', 'lng': 'sv' },

    // util
    { 'code': 'Etuveto', 'text': 'Etuveto', 'lng': 'fi' },
    { 'code': 'Etuveto', 'text': 'Framhjulsdrift', 'lng': 'sv' },
    { 'code': 'Takaveto', 'text': 'Takaveto', 'lng': 'fi' },
    { 'code': 'Takaveto', 'text': 'Bakhjulsdrift', 'lng': 'sv' },
    { 'code': 'Neliveto', 'text': 'Neliveto', 'lng': 'fi' },
    { 'code': 'Neliveto', 'text': 'Fyrhjulsdrift', 'lng': 'sv' },

    // Payment
    { 'code': 'Valitse maksutapa', 'text': 'Valitse maksutapa', 'lng': 'fi' },
    { 'code': 'Valitse maksutapa', 'text': 'Välj betalningssätt', 'lng': 'sv' },
    { 'code': 'syötä promokoodi', 'text': '... tai syötä promokoodi', 'lng': 'fi' },
    { 'code': 'syötä promokoodi', 'text': '... eller skriv in promokod', 'lng': 'sv' },
    { 'code': 'ok', 'text': 'OK', 'lng': 'fi' },
    { 'code': 'ok', 'text': 'OK', 'lng': 'sv' },
    { 'code': 'maksu keskeytetty', 'text': 'Maksu keskeytetty, maksua ei suoritettu.', 'lng': 'fi' },
    { 'code': 'maksu keskeytetty', 'text': 'Betalningen avbröts, betalning har inte gjorts.', 'lng': 'sv' },
    { 'code': 'haku keskeytetty palaa alkuun', 'text': 'Haku keskeytetty, klikkaa Palaa alkuun -nappia.', 'lng': 'fi' },
    { 'code': 'haku keskeytetty palaa alkuun', 'text': 'Sökningen avbröts, klicka på Till början -knappen.', 'lng': 'sv' },
    
    // Lookup
    { 'code': 'palaa alkuun', 'text': 'Palaa alkuun', 'lng': 'fi' },
    { 'code': 'palaa alkuun', 'text': 'Till början', 'lng': 'sv' },
    { 'code': 'Tulosta', 'text': 'Tulosta', 'lng': 'fi' },
    { 'code': 'Tulosta', 'text': 'Skriv ut', 'lng': 'sv' },
    { 'code': 'ei löydy tai muu virhe', 'text': 'ei löydy tai muu virhe', 'lng': 'fi' },
    { 'code': 'ei löydy tai muu virhe', 'text': 'sökning mislyckades eller annat fel', 'lng': 'sv' },
    { 'code': 'Ei tietoja', 'text': 'Ei tietoja', 'lng': 'fi' },
    { 'code': 'Ei tietoja', 'text': 'Inga uppgifter', 'lng': 'sv' },
    { 'code': 'eitietoa', 'text': 'ei tietoa', 'lng': 'fi' },
    { 'code': 'eitietoa', 'text': 'okänd', 'lng': 'sv' },
    { 'code': 'Kyselyssä tapahtui virhe', 'text': 'Kyselyssä tapahtui virhe', 'lng': 'fi' },
    { 'code': 'Kyselyssä tapahtui virhe', 'text': 'Fel vid sökning', 'lng': 'sv' },

    { 'code': 'Huomio', 'text': 'Huomioi nämä asiat', 'lng': 'fi' },
    { 'code': 'Huomio', 'text': 'Notera följande', 'lng': 'sv' },
    { 'code': 'autokanta-analyysi', 'text': 'Autokanta poimii tähän asiat, joihin kannattaa kiinnittää huomiota. Tämän osion huomiot perustuvat Traficomista haettuun tietoon, mutta niiden tulkinnasta vastaa Autokanta.fi. Jos jokin mietityttää voit kirjoittaa meille osoitteeseen info (at) autokanta.fi.', 'lng': 'fi' },
    { 'code': 'autokanta-analyysi', 'text': 'Autokanta pekar ut saker som är värda att noteras. Uppgifterna i denna sektion bygger på data som hämtats från Traficom, men Autokanta.fi ansvarar för tolkningen av dessa. Om det är något du undrar över, kan du skriva till oss på info (at) autokanta.fi.', 'lng': 'sv' },
    { 'code': 'Huomautukset', 'text': 'Huomioi', 'lng': 'fi' },
    { 'code': 'Huomautukset', 'text': 'Notera', 'lng': 'sv' },
    { 'code': 'huomVaihdettujaOsia', 'text': 'Vaihdettujen osien osuus %', 'lng': 'fi' },
    { 'code': 'huomVaihdettujaOsia', 'text': 'Utbytta delar sammanlagt %', 'lng': 'sv' },
    { 'code': 'huomVaihdettujaOsiaSelite', 'text': 'Ajoneuvoon on tehty muutoksia ja se on rekisteröintikatsastettu uudestaan. Mahdollinen syy voi olla kolarivaurio.', 'lng': 'fi' },
    { 'code': 'huomVaihdettujaOsiaSelite', 'text': 'Fordonet har ändrats och den har registreringsbesiktats på nytt. Möjlig orsak kan vara krockskada.', 'lng': 'sv' },
    { 'code': 'huomKatsastusEraantynyt', 'text': 'Katsastusaika on erääntynyt', 'lng': 'fi' },
    { 'code': 'huomKatsastusEraantynyt', 'text': 'Besiktningstiden har gått ut', 'lng': 'sv' },
    { 'code': 'huomKatsastusEraantynytSelite', 'text': 'Tarkista rajoitukset ja katsastushistoria', 'lng': 'fi' },
    { 'code': 'huomKatsastusEraantynytSelite', 'text': 'Kontrollera begränsningarna och besiktninghistoria', 'lng': 'sv' },
    { 'code': 'huomKatsastusEraantyy', 'text': 'Päiviä seuraavaan katsastukseen', 'lng': 'fi' },
    { 'code': 'huomKatsastusEraantyy', 'text': 'Dagar till nästa besiktning', 'lng': 'sv' },
    { 'code': 'huomKatsastusEraantyySelite', 'text': 'Tarkista katsastukset.', 'lng': 'fi' },
    { 'code': 'huomKatsastusEraantyySelite', 'text': 'Kontrollera besiktninghistoria', 'lng': 'sv' },
    { 'code': 'huomAutoverovapaus', 'text': 'Ajoneuvolla on autoverovapaus.', 'lng': 'fi' },
    { 'code': 'huomAutoverovapaus', 'text': 'Fordonet är befriat från bilskatt.', 'lng': 'sv' },
    { 'code': 'huomAutoverovapausSelite', 'text': 'Auto voi olla eri syistä vapautettu autoverosta, esim. muuttoauto. Vapautus voi olla määräaikainen. Autoverovapaus ei vaikuta vuotuiseen ajoneuvoveroon.', 'lng': 'fi' },
    { 'code': 'huomAutoverovapausSelite', 'text': 'Bilen kan befrias från bilskatt pga olika orsaker, t.ex. flyttbilar. Befrielsen kan vara tidsbunden. Befrielse från bilskatt påverkar inte den årliga fordonsskatten.', 'lng': 'sv' },
    { 'code': 'huomUseitaTunnuksia', 'text': 'Ajoneuvolla on useita rekisterinumeroita/tunnuksia.', 'lng': 'fi' },
    { 'code': 'huomUseitaTunnuksia', 'text': 'Fordonet har flera registreringsnummer/tecken.', 'lng': 'sv' },
    { 'code': 'huomUseitaTunnuksiaSelite', 'text': 'Ajoneuvon rekisterinumero/rekisteritunnus on muuttunut. Tarkista rekisteritunnukset', 'lng': 'fi' },
    { 'code': 'huomUseitaTunnuksiaSelite', 'text': 'Fordonetss registereringsnummer/tecken har ändrats. Kontrollera reg.nummer', 'lng': 'sv' },
    { 'code': 'huomMuuPoisto', 'text': 'Muu kuin tavallinen poisto.', 'lng': 'fi' },
    { 'code': 'huomMuuPoisto', 'text': 'Annan än vanlig avregistrering.', 'lng': 'sv' },
    { 'code': 'huomMuuPoistoSelite', 'text': 'Tarkista poistot.', 'lng': 'fi' },
    { 'code': 'huomMuuPoistoSelite', 'text': 'Kontrollera avregistreringar', 'lng': 'sv' },
    { 'code': 'huomMuutoskatsastus', 'text': 'Muutoskatsastus.', 'lng': 'fi' },
    { 'code': 'huomMuutoskatsastus', 'text': 'Ändringsbesiktning.', 'lng': 'sv' },
    { 'code': 'huomMuutoskatsastusSelite', 'text': 'Ajoneuvoo pitää yleensä muutoskatsastaa jos sen rakenne tai käyttötarkoitus muuttuu.', 'lng': 'fi' },
    { 'code': 'huomMuutoskatsastusSelite', 'text': 'Ett fordon måste vanligen genomgå en ändringsbesiktning om dess konstruktion eller användningsändamål ändras väsentligt.', 'lng': 'sv' },
    { 'code': 'huomRakennettuAjoneuvo', 'text': 'Rakennettu ajoneuvo.', 'lng': 'fi' },
    { 'code': 'huomRakennettuAjoneuvo', 'text': 'Rakennettu ajoneuvo.', 'lng': 'sv' },
    { 'code': 'huomRakennettuAjoneuvoSelite', 'text': 'Ajoneuvoon tehty huomattavia muutoksia.', 'lng': 'fi' },
    { 'code': 'huomRakennettuAjoneuvoSelite', 'text': 'Fordonet har ändrats i väsentlig grad.', 'lng': 'sv' },
    { 'code': 'huomMuuKaytto', 'text': 'Käyttöhistoriassa muu kuin yksityinen käyttö tai myyntivarasto.', 'lng': 'fi' },
    { 'code': 'huomMuuKaytto', 'text': 'Användningshistoria, annan än privat användning eller försäljningslager.', 'lng': 'sv' },
    { 'code': 'huomMuuKayttoSelite', 'text': 'Tarkista käyttöhistoriasta luvanvarainen, vuokrauskäyttö jne.', 'lng': 'fi' },
    { 'code': 'huomMuuKayttoSelite', 'text': 'Kontrollera användningshistoria för tillståndspliktig använding, uthyrning osv.', 'lng': 'sv' },
    { 'code': 'huomErikoisehto', 'text': 'Ajoneuvoon liittyy erikoisehtoja.', 'lng': 'fi' },
    { 'code': 'huomErikoisehto', 'text': 'Fordonet har specialvillkor.', 'lng': 'sv' },
    { 'code': 'huomErikoisehtoSelite', 'text': 'Tarkista erikoisehdot.', 'lng': 'fi' },
    { 'code': 'huomErikoisehtoSelite', 'text': 'Kontrollera specialvillkoren.', 'lng': 'sv' },
    { 'code': 'huomYksittainTuotu', 'text': 'Ajoneuvo on maahantuotu käytettynä.', 'lng': 'fi' },
    { 'code': 'huomYksittainTuotu', 'text': 'Fordonet har importerats som begagnad.', 'lng': 'sv' },
    { 'code': 'huomYksittainTuotuSelite', 'text': 'Tarkista käyttöönotto- ja ensirekisteröintipäivämäärät.', 'lng': 'fi' },
    { 'code': 'huomYksittainTuotuSelite', 'text': 'Kontrollera i trafik och första registreringsdatum.', 'lng': 'sv' },
    { 'code': 'huomYksittainTuotuUutena', 'text': 'Ajoneuvo on yksittäin uutena maahantuotu.', 'lng': 'fi' },
    { 'code': 'huomYksittainTuotuUutena', 'text': 'Fordonet är privatimporterat som ny.', 'lng': 'sv' },
    { 'code': 'huomYksittainTuotuUutenaSelite', 'text': 'Tarkista käyttöönotto- ja ensirekisteröintipäivämäärät.', 'lng': 'fi' },
    { 'code': 'huomYksittainTuotuUutenaSelite', 'text': 'Kontrollera i trafik och första registreringsdatum.', 'lng': 'sv' },
    { 'code': 'huomPvmero', 'text': 'Käyttöönottopäivä on aikaisempi kuin ensirekisteröinti Suomessa.', 'lng': 'fi' },
    { 'code': 'huomPvmero', 'text': 'I trafik datum tidigare än förstaregistreringsdatum i Finland.', 'lng': 'sv' },
    { 'code': 'huomPvmeroSelite', 'text': 'Mahdollisesti käytettynä maahantuotu. Tarkista käyttöönotto- ja ensirekisteröintipäivämäärät', 'lng': 'fi' },
    { 'code': 'huomPvmeroSelite', 'text': 'Möjligen privatimport. Kontrollera i trafik och första registreringsdatum', 'lng': 'sv' },
    { 'code': 'huomEnnakkoilm', 'text': 'Ennakkoilmoituspäivä on aikaisempi kuin ensirekisteröinti Suomessa.', 'lng': 'fi' },
    { 'code': 'huomEnnakkoilm', 'text': 'Förhandsanmälningsdatum är tidigare än förstaregistreringsdatum i Finland.', 'lng': 'sv' },
    { 'code': 'huomEnnakkoilmSelite', 'text': 'Tarkista ennakkoilmoitus- ja ensirekisteröintipäivämäärät. Yleensä näillä päivämäärillä ei ole suurta eroa.', 'lng': 'fi' },
    { 'code': 'huomEnnakkoilmSelite', 'text': 'Kontrollera förhandsanmälningsdatum och första registreringsdatum. Normalt finns det inte stor skillnad mellan dessa datum.', 'lng': 'sv' },

    { 'code': 'Rajoitukset', 'text': 'Rajoitukset', 'lng': 'fi' },
    { 'code': 'Rajoitukset', 'text': 'Begränsningar', 'lng': 'sv' },
    { 'code': 'Omistajat ja haltijat', 'text': 'Omistajat ja haltijat', 'lng': 'fi' },
    { 'code': 'Omistajat ja haltijat', 'text': 'Ägare och innehavare', 'lng': 'sv' },
    { 'code': 'Päivämäärät', 'text': 'Perustiedot', 'lng': 'fi' },
    { 'code': 'Päivämäärät', 'text': 'Basuppgifter', 'lng': 'sv' },
    { 'code': 'Käyttö', 'text': 'Käyttö', 'lng': 'fi' },
    { 'code': 'Käyttö', 'text': 'Användning', 'lng': 'sv' },
    { 'code': 'Tunnukset', 'text': 'Rekisterinumerot/tunnukset', 'lng': 'fi' },
    { 'code': 'Tunnukset', 'text': 'Reg.nummer', 'lng': 'sv' },
    { 'code': 'Tekniikka', 'text': 'Tekniikka', 'lng': 'fi' },
    { 'code': 'Tekniikka', 'text': 'Teknik', 'lng': 'sv' },
    { 'code': 'Katsastus', 'text': 'Katsastus', 'lng': 'fi' },
    { 'code': 'Katsastus', 'text': 'Besiktning', 'lng': 'sv' },
    { 'code': 'Erikoisehdot', 'text': 'Erikoisehdot', 'lng': 'fi' },
    { 'code': 'Erikoisehdot', 'text': 'Specialvillkor', 'lng': 'sv' },
    { 'code': 'Poistot', 'text': 'Poistot', 'lng': 'fi' },
    { 'code': 'Poistot', 'text': 'Avregistreringar', 'lng': 'sv' },
    { 'code': 'Kayttohistoria', 'text': 'Käyttöhistoria', 'lng': 'fi' },
    { 'code': 'Kayttohistoria', 'text': 'Användningshistoria', 'lng': 'sv' },
    { 'code': 'Vakuutustiedot', 'text': 'Vakuutustiedot', 'lng': 'fi' },
    { 'code': 'Vakuutustiedot', 'text': 'Försäkringsuppgifter', 'lng': 'sv' },
    { 'code': 'Mittarilukemat', 'text': 'Mittarilukemat', 'lng': 'fi' },
    { 'code': 'Mittarilukemat', 'text': 'Mätarställningar', 'lng': 'sv' },
    { 'code': 'MitatPainot', 'text': 'Mitat (mm) ja painot (kg)', 'lng': 'fi' },
    { 'code': 'MitatPainot', 'text': 'Mått (mm) och vikter (kg)', 'lng': 'sv' },
    { 'code': 'AkselitRenkaat', 'text': 'Akselit ja renkaat', 'lng': 'fi' },
    { 'code': 'AkselitRenkaat', 'text': 'Axlar och däck', 'lng': 'sv' },
    { 'code': 'Turvavarusteet', 'text': 'Turvavarusteet', 'lng': 'fi' },
    { 'code': 'Turvavarusteet', 'text': 'Säkerhetsutrustning', 'lng': 'sv' },

    { 'code': 'yksittaisKayttovoima', 'text': 'Käyttövoima', 'lng': 'fi' },
    { 'code': 'suurinNettoteho', 'text': 'Teho kW', 'lng': 'fi' },
    { 'code': 'sylintereidenLkm', 'text': 'Sylintereiden lukumäärä', 'lng': 'fi' },
    { 'code': 'valijaahdytin', 'text': 'Välijäähdytin', 'lng': 'fi' },
    { 'code': 'vaihteidenLkm', 'text': 'Vaihteiden lukumäärä', 'lng': 'fi' },
    { 'code': 'vaihteisto', 'text': 'Vaihteisto', 'lng': 'fi' },
    { 'code': 'kulutuslaji', 'text': 'Kulutuslaji', 'lng': 'fi' },
    { 'code': 'maara', 'text': 'Kulutus l/100km', 'lng': 'fi' },
    { 'code': 'ahdin', 'text': 'Ahdin', 'lng': 'fi' },
    { 'code': 'sijainti', 'text': 'Sijainti', 'lng': 'fi' },
    { 'code': 'vetava', 'text': 'Vetävä', 'lng': 'fi' },
    { 'code': 'iskutilavuus', 'text': 'Iskutilavuus cm3', 'lng': 'fi' },
    { 'code': 'kayttovoima', 'text': 'Käyttövoima', 'lng': 'fi' },
    { 'code': 'paastotaso', 'text': 'Päästötaso', 'lng': 'fi' },
    { 'code': 'huippunopeus', 'text': 'Huippunopeus km/t', 'lng': 'fi' },
    { 'code': 'sahkohybridi', 'text': 'Sähköhybridi', 'lng': 'fi' },
    { 'code': 'sahkohybridinluokka', 'text': 'Sähköhybridin luokka', 'lng': 'fi' },
    { 'code': 'yksittaisKayttovoima', 'text': 'Drivmedel', 'lng': 'sv' },
    { 'code': 'suurinNettoteho', 'text': 'Effekt kW', 'lng': 'sv' },
    { 'code': 'sylintereidenLkm', 'text': 'Cylinderantal', 'lng': 'sv' },
    { 'code': 'valijaahdytin', 'text': 'Intercooler', 'lng': 'sv' },
    { 'code': 'vaihteidenLkm', 'text': 'Antal växlar', 'lng': 'sv' },
    { 'code': 'vaihteisto', 'text': 'Växellåda', 'lng': 'sv' },
    { 'code': 'kulutuslaji', 'text': 'Förbrukningsslag', 'lng': 'sv' },
    { 'code': 'maara', 'text': 'Förbrukning l/100km', 'lng': 'sv' },
    { 'code': 'ahdin', 'text': 'Turbo', 'lng': 'sv' },
    { 'code': 'sijainti', 'text': 'Läge', 'lng': 'sv' },
    { 'code': 'vetava', 'text': 'Drivande', 'lng': 'sv' },
    { 'code': 'iskutilavuus', 'text': 'Cylindervolym cm3', 'lng': 'sv' },
    { 'code': 'kayttovoima', 'text': 'Drivmedel', 'lng': 'sv' },
    { 'code': 'paastotaso', 'text': 'Utsläppsnivå', 'lng': 'sv' },
    { 'code': 'huippunopeus', 'text': 'Max hastighet km/t', 'lng': 'sv' },
    { 'code': 'sahkohybridi', 'text': 'Elhybrid', 'lng': 'sv' },
    { 'code': 'sahkohybridinluokka', 'text': 'Elhybridklass', 'lng': 'sv' },
    { "code": "pakokaasunpuhdistus", "text": "Pakokaasunpuhdistus", "lng": "fi" },
    { "code": "pakokaasunpuhdistus", "text": "Avgasrengöring", "lng": "sv" },
    { "code": "ilmoituspvm", "text": "Ennakkoilmoituspäivä", "lng": "fi" },
    { "code": "ilmoituspvm", "text": "Förhandsanmälningsdatum", "lng": "sv" },
    { "code": "ennakkoilmoituspvm", "text": "Ennakkoilmoituspäivä", "lng": "fi" },
    { "code": "ennakkoilmoituspvm", "text": "Förhandsanmälningsdatum", "lng": "sv" },
    { "code": "ensirekisterointipvm", "text": "Ensirekisteröintipäivä", "lng": "fi" },
    { "code": "ensirekisterointipvm", "text": "Första registreringsdatum", "lng": "sv" },
    { "code": "kayttoonottopvm", "text": "Käyttöönottopäivä", "lng": "fi" },
    { "code": "kayttoonottopvm", "text": "Ibruktagningsdatum", "lng": "sv" },
    { "code": "kayttoonotto", "text": "Käyttöönotto", "lng": "fi" },
    { "code": "kayttoonotto", "text": "I trafik", "lng": "sv" },
    { "code": "tila", "text": "Tila", "lng": "fi" },
    { "code": "tila", "text": "Status", "lng": "sv" },
    { "code": "ajoneuvolaji", "text": "Ajoneuvolaji", "lng": "fi" },
    { "code": "ajoneuvolaji", "text": "Fordonsslag", "lng": "sv" },
    { "code": "ajoneuvoluokka", "text": "Ajoneuvoluokka", "lng": "fi" },
    { "code": "ajoneuvoluokka", "text": "Fordonsklass", "lng": "sv" },
    { "code": "ajoneuvoryhmä", "text": "Ajoneuvoryhmä", "lng": "fi" },
    { "code": "ajoneuvoryhmä", "text": "Fordonsgrupp", "lng": "sv" },
    { "code": "tila", "text": "Tila", "lng": "fi" },
    { "code": "tila", "text": "Status", "lng": "sv" },
    { "code": "rektodistus1", "text": "Rekisteröintitodistus 1", "lng": "fi" },
    { "code": "rektodistus1", "text": "Registreringsbevis 1", "lng": "sv" },
    { "code": "rektodistus2", "text": "Rekisteröintitodistus 2", "lng": "fi" },
    { "code": "rektodistus2", "text": "Registreringsbevis 2", "lng": "sv" },
    { "code": "valmistenumero", "text": "Valmistenumero (VIN)", "lng": "fi" },
    { "code": "valmistenumero", "text": "Tillverkningsnummer (VIN)", "lng": "sv" },
    { "code": "yksittäinmaahantuotu", "text": "Yksittäinmaahantuotu", "lng": "fi" },
    { "code": "yksittäinmaahantuotu", "text": "Enskilt importerad", "lng": "sv" },
    { "code": "tuontimaa", "text": "Tuontimaa", "lng": "fi" },
    { "code": "tuontimaa", "text": "Importerad från", "lng": "sv" },
    { "code": "tyyppihyväksyntä", "text": "Tyyppihyväksyntä", "lng": "fi" },
    { "code": "tyyppihyväksyntä", "text": "Typgodkännande", "lng": "sv" },
    { "code": "variantti", "text": "Variantti", "lng": "fi" },
    { "code": "variantti", "text": "Variant", "lng": "sv" },
    { "code": "versio", "text": "Versio", "lng": "fi" },
    { "code": "versio", "text": "Version", "lng": "sv" },
    { "code": "katsastusajankohta", "text": "viimeisin katsastus", "lng": "fi" },
    { "code": "katsastusajankohta", "text": "senaste besiktning", "lng": "sv" },
    { "code": "katsastuspaatos", "text": "Katsastuspäätös", "lng": "fi" },
    { "code": "katsastuspaatos", "text": "Besiktningsbeslut", "lng": "sv" },
    { "code": "mkAjanAlkupvm", "text": "määräaikaiskatsastusajanjakso alkaa", "lng": "fi" },
    { "code": "mkAjanAlkupvm", "text": "besiktningsperiod börjar", "lng": "sv" },
    { "code": "mkAjanLoppupvm", "text": "määräaikaiskatsastusajanjakso päättyy", "lng": "fi" },
    { "code": "mkAjanLoppupvm", "text": "besiktningsperiod avslutas", "lng": "sv" },

    { "code": "Kulutus ja päästöt", "text": "Kulutus ja päästöt", "lng": "fi" },
    { "code": "Kulutus ja päästöt", "text": "Förbrukning och utsläpp", "lng": "sv" },
    { "code": "päästötyyppi", "text": "Päästötyyppi", "lng": "fi" },
    { "code": "päästötyyppi", "text": "Utsläppstyp", "lng": "sv" },
    { "code": "päästölaji", "text": "Päästölaji", "lng": "fi" },
    { "code": "päästölaji", "text": "Utsläppsslag", "lng": "sv" },
    { "code": "eikulutustietoa", "text": "ei kulutustietoja ", "lng": "fi" },
    { "code": "eikulutustietoa", "text": "inga uppgifter om förbrukning ", "lng": "sv" },
    { "code": "eipäästötietoa", "text": "ei päästötietoja ", "lng": "fi" },
    { "code": "eipäästötietoa", "text": "inga uppgifter om utsläpp ", "lng": "sv" },

    { "code": "ajonKokPituus", "text": "Kokonaispituus", "lng": "fi" },
    { "code": "ajonKokPituus", "text": "Totallängd", "lng": "sv" },
    { "code": "ajonLeveys", "text": "Leveys", "lng": "fi" },
    { "code": "ajonLeveys", "text": "Bredd", "lng": "sv" },
    { "code": "ajonKorkeus", "text": "Korkeus", "lng": "fi" },
    { "code": "ajonKorkeus", "text": "Höjd", "lng": "sv" },
    { "code": "omamassa", "text": "Omamassa", "lng": "fi" },
    { "code": "omamassa", "text": "Egenvikt", "lng": "sv" },
    { "code": "teknSuurSallKokmassa", "text": "Tekninen suurin sallittu kokonaismassa", "lng": "fi" },
    { "code": "teknSuurSallKokmassa", "text": "Största tekniskt tillåtna totalmassa", "lng": "sv" },
    { "code": "yhdistelmanSuurSallMassa", "text": "Yhdistelmän suurin sallittu kokonaismassa", "lng": "fi" },
    { "code": "yhdistelmanSuurSallMassa", "text": "Kombinationens största tillåtna totalmassa", "lng": "sv" },
    { "code": "massaJarruitta", "text": "Perävaunumassa jarruitta", "lng": "fi" },
    { "code": "massaJarruitta", "text": "Släpvagnsmassa utan bromsar", "lng": "sv" },
    { "code": "massaJarruin", "text": "Perävaunumassa jarruin", "lng": "fi" },
    { "code": "massaJarruin", "text": "Släpvagnsmassa med bromsar", "lng": "sv" },
    { "code": "massaJarruittaValmSall", "text": "Valm. sallima perävaunumassa jarruitta", "lng": "fi" },
    { "code": "massaJarruittaValmSall", "text": "Av tillverkaren tillåtna släpvagnsmassa utan bromsar", "lng": "sv" },
    { "code": "massaJarruinValmSall", "text": "Valm. sallima perävaunumassa jarruin", "lng": "fi" },
    { "code": "massaJarruinValmSall", "text": "Av tillverkaren tillåtna släpvagnsmassa med bromsar", "lng": "sv" },

    { "code": "penkkirivi", "text": "Penkkirivi", "lng": "fi" },
    { "code": "penkkirivi", "text": "Bänkrad", "lng": "sv" },

    { "code": "akseli", "text": "Akseli", "lng": "fi" },
    { "code": "akseli", "text": "Axel", "lng": "sv" },
    { "code": "ohjaava", "text": "  Ohjaava", "lng": "fi" },
    { "code": "ohjaava", "text": "  Styrande", "lng": "sv" },
    { "code": "vetava", "text": "  Vetävä", "lng": "fi" },
    { "code": "vetava", "text": "  Drivande", "lng": "sv" },
    { "code": "teknSuurSallMassa", "text": "  Tekninen suurin sallittu massa", "lng": "fi" },
    { "code": "teknSuurSallMassa", "text": "  Största tekniskt tillåtna massa", "lng": "sv" },
    { "code": "rengaskoko", "text": "Rengaskoko", "lng": "fi" },
    { "code": "rengaskoko", "text": "Däckstorlek", "lng": "sv" },
    { "code": "vannekoko", "text": "Vannekoko", "lng": "fi" },
    { "code": "vannekoko", "text": "Fälgstorlek", "lng": "sv" },
    { "code": "offset", "text": "Offset", "lng": "fi" },
    { "code": "offset", "text": "Offset", "lng": "sv" },
    { "code": "luokka", "text": "Luokka", "lng": "fi" },
    { "code": "luokka", "text": "Klass", "lng": "sv" },

    { "code": "x", "text": "x", "lng": "fi" },
    { "code": "x", "text": "x", "lng": "sv" },


    { "code": "suurinNettoteho", "text": "Teho kW", "lng": "fi" },
    { "code": "suurinNettoteho", "text": "Effekt kW", "lng": "sv" },
    { "code": "yksityinen", "text": "Yksityinen", "lng": "fi" },
    { "code": "yksityinen", "text": "Privat", "lng": "sv" },
    { "code": "kyllä", "text": "Kyllä", "lng": "fi" },
    { "code": "kyllä", "text": "Ja", "lng": "sv" },
    { "code": "ei", "text": "Ei", "lng": "fi" },
    { "code": "ei", "text": "Nej", "lng": "sv" },
    { "code": "alkaen", "text": "alkaen", "lng": "fi" },
    { "code": "alkaen", "text": "från", "lng": "sv" },
    { "code": "ajalla", "text": "ajalla", "lng": "fi" },
    { "code": "ajalla", "text": "från/till", "lng": "sv" },
    { "code": "näytä lisää", "text": "Näytä lisää", "lng": "fi" },
    { "code": "näytä lisää", "text": "Visa mera", "lng": "sv" },
    { "code": "näytä vähemmän", "text": "Näytä vähemmän", "lng": "fi" },
    { "code": "näytä vähemmän", "text": "Visa mindre", "lng": "sv" },
    { "code": "yksityishenkilö", "text": "Yksityishenkilö", "lng": "fi" },
    { "code": "yksityishenkilö", "text": "Privatperson", "lng": "sv" },
    { "code": "entinen", "text": "Entinen", "lng": "fi" },
    { "code": "entinen", "text": "Tidigare", "lng": "sv" },
    { "code": "kuolinpesä", "text": "(kuolinpesä)", "lng": "fi" },
    { "code": "kuolinpesä", "text": "(dödsbo)", "lng": "sv" },
    { "code": "ei saatavilla", "text": "Tietoja ei saatavilla", "lng": "fi" },
    { "code": "ei saatavilla", "text": "Uppgifterna är inte tillgängliga", "lng": "sv" },
    { "code": "jump here", "text": " täältä", "lng": "fi" },
    { "code": "jump here", "text": " här", "lng": "sv" },
    { "code": "aikaisempia", "text": " - aikaisempia omistajia/haltijoita ", "lng": "fi" },
    { "code": "aikaisempia", "text": " - tidigare ägare/innehavare ", "lng": "sv" },
  ]
